"use client";
import React, { useState } from "react";
import {
  motion,
  AnimatePresence,
  useScroll,
  useMotionValueEvent,
} from "framer-motion";
import Link from "next/link";
import { cn } from "@/lib/utils";
import Image from "next/image";

export const FloatingNav = ({
  navItems,
  className,
}: {
  navItems: {
    name: string;
    link: string;
    iconSrc?: string;
  }[];
  className?: string;
}) => {
  const { scrollYProgress } = useScroll();
  const [visible, setVisible] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useMotionValueEvent(scrollYProgress, "change", (current) => {
    if (typeof current === "number") {
      let direction = current - scrollYProgress.getPrevious()!;
      if (scrollYProgress.get() < 0.05) {
        setVisible(true);
      } else {
        if (direction < 0) {
          setVisible(true);
        } else {
          setVisible(false);
        }
      }
    }
  });

  return (
    <AnimatePresence mode="wait">
      <motion.div
        initial={{
          opacity: 1,
          y: -100,
        }}
        animate={{
          y: visible ? 0 : -100,
          opacity: visible ? 1 : 0,
        }}
        transition={{
          duration: 0.2,
        }}
        className={cn(
          "flex items-center justify-between max-w-fit md:min-w-[70vw] lg:min-w-fit fixed z-[5000] top-4 inset-x-0 mx-auto px-4 py-2 md:px-6 md:py-3 lg:px-8 lg:py-4 rounded-full border border-black/.1 shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]",
          className
        )}
        style={{
          backdropFilter: "blur(16px) saturate(180%)",
          backgroundColor: "rgba(17, 25, 40, 0.75)",
          borderRadius: "24px",
          border: "1px solid rgba(255, 255, 255, 0.125)",
          cursor: isMenuOpen ? "pointer" : "default",
        }}
        onClick={() => isMenuOpen && setIsMenuOpen(false)}
      >
        {/* Logotipo ajustado para manter a proporção em todas as telas */}
        <Link href="/" className="flex items-center">
          <Image
            src="https://firebasestorage.googleapis.com/v0/b/player-music-72d4d.appspot.com/o/img%2Ff24f2140-978a-4ae4-af06-8e13b5048c68.png?alt=media&token=3f919229-cd00-4a45-ab4a-4c90111b1473"
            alt="PagShield"
            width={160} // Largura fixa para telas menores e médias
            height={40} // Altura inicial
            className="md:w-[150px] lg:w-[150px] h-auto" // Largura ajustada para telas maiores, mantendo proporção com h-auto
          />
        </Link>

        {/* Botão de menu ajustado para telas menores */}
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="md:hidden p-2 focus:outline-none"
          style={{ color: "#6B21A8", fontSize: "1.5rem" }} // Tamanho aumentado para o ícone
        >
          <span className="text-xl font-bold" style={{ color: "#6B21A8" }}>
            {isMenuOpen ? "×" : "☰"}
          </span>
        </button>

        <div className="hidden md:flex space-x-4">
          {navItems.map((navItem: any, idx: number) => (
            <Link
              key={`link=${idx}`}
              href={navItem.link}
              className="relative items-center flex space-x-2 group"
              style={{ color: "#CBACF9" }}
            >
              {navItem.name}
            </Link>
          ))}
        </div>
      </motion.div>

      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="fixed inset-0 bg-black bg-opacity-80 flex flex-col items-center justify-center z-[4000]"
          >
            {navItems.map((navItem: any, idx: number) => (
              <Link
                key={`modal-link-${idx}`}
                href={navItem.link}
                className="text-xl mb-4"
                style={{ color: "#CBACF9" }}
                onClick={() => setIsMenuOpen(false)}
              >
                {navItem.name}
              </Link>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </AnimatePresence>
  );
};
