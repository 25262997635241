// @ts-nocheck
import Image from 'next/image';

export const navItems = [
   {
    name: "",
    link: "#",
    iconSrc: "https://firebasestorage.googleapis.com/v0/b/player-music-72d4d.appspot.com/o/img%2Ff24f2140-978a-4ae4-af06-8e13b5048c68.png?alt=media&token=3f919229-cd00-4a45-ab4a-4c90111b1473",
  },
  { name: "FAQ", link: "#faq" },
  { name: "Cadastro", link: "https://app.pagshield.io/register" },
  { name: "Login", link: "https://app.pagshield.io/login" },
];



export const gridItems = [
  {
    id: 2,
    title: "Aceite Pagamentos Nacionais e Internacionais",
    className: "lg:col-span-2 md:col-span-3 md:row-span-2",
    imgClassName: "",
    titleClassName: "justify-start",
    spareImg: "",
  },
  {
    id: 8,
    title: "Ferramentas para Impulsionar suas Vendas",
    description: "Contamos com as melhores ferramentas de recuperação de venda",
    className: "lg:col-span-2 md:col-span-3 md:row-span-2",
    imgClassName: "",
    titleClassName: "justify-center",
    img: "",
    spareImg: "",
  },
  {
    id: 10,
    title: "Segurança de Ponta a Ponta",
    description: "Contamos com as melhores práticas de segurança para proteger seus dados e os de seus clientes.",
    className: "lg:col-span-2 md:col-span-3 md:row-span-2",
    imgClassName: "",
    titleClassName: "justify-center",
    img: "",
    spareImg: "",
  },
  {
    id: 4,
    title: "Explicação dos Serviços de Tecnologia para Vendas",
    description: "Explicar claramente os serviços oferecidos de tecnologia voltada a vendas",
    className: "lg:col-span-2 md:col-span-3 md:row-span-2",
    imgClassName: "",
    titleClassName: "justify-center",
    img: "",
    spareImg: "",
  },
  {
    id: 5,
    title: "Análise de Dados em Tempo Real",
    description: "Obtenha insights detalhados sobre suas vendas para tomar decisões rapidamente.",
    className: "lg:col-span-2 md:col-span-3 md:row-span-2",
    imgClassName: "",
    titleClassName: "justify-center",
    img: "",
    spareImg: "",
  },
  {
    id: 9,
    title: "Suporte ao Cliente 24/7",
    description: "Nossa equipe está disponível para auxiliar você em qualquer necessidade a qualquer momento.",
    className: "lg:col-span-2 md:col-span-3 md:row-span-2",
    imgClassName: "",
    titleClassName: "justify-center",
    img: "",
    spareImg: "",
  },
];





export const testimonials = [
  {
    id: 1,
    img: "https://www.axionpay.com.br/_next/image?url=%2Fcarousel%2Fmeta.webp&w=256&q=75",
    nameImg: "/cloudName.svg",
  },
  {
    id: 2,
    img: "https://www.axionpay.com.br/_next/image?url=%2Fcarousel%2Fgoogle.webp&w=256&q=75",
    nameImg: "/appName.svg",
    
  },
  {
    id: 3,
    img: "https://www.axionpay.com.br/_next/image?url=%2Fcarousel%2Fshopify.webp&w=256&q=75",
    nameImg: "/hostName.svg",
  },
  {
    id: 4,
    img: "https://www.axionpay.com.br/_next/image?url=%2Fcarousel%2Ftaboola.webp&w=256&q=75",
    nameImg: "/streamName.svg",
  },
  {
    id: 5,
    img: "https://page.vegacheckout.com.br/img/vega.svg",
    nameImg: "/dockerName.svg",
  },
  {
    id: 6,
    img: "https://www.axionpay.com.br/_next/image?url=%2Fcarousel%2Ftiktok.webp&w=256&q=75",
    nameImg: "/tiktok.svg",
  },
  {
    id: 7,
    img: "https://reportana.com/themes/reportana/assets/img/reportana-white-logo.svg",
    nameImg: "/reportana.svg",
  },
    {
    id: 8,
    img: "https://www.adoorei.com.br/img/logo.dfb5eb16.svg",
    nameImg: "/reportana.svg",
  },
];

export const companies = [
  {
    id: 1,
    name: "cloudinary",
    img: "/cloud.svg",
    nameImg: "/cloudName.svg",
  },
  {
    id: 2,
    name: "appwrite",
    img: "/app.svg",
    nameImg: "/appName.svg",
  },
  {
    id: 3,
    name: "HOSTINGER",
    img: "/host.svg",
    nameImg: "/hostName.svg",
  },
  {
    id: 4,
    name: "stream",
    img: "/s.svg",
    nameImg: "/streamName.svg",
  },
  {
    id: 5,
    name: "docker.",
    img: "/dock.svg",
    nameImg: "/dockerName.svg",
  },
];

export const workExperience = [
{
  id: 1,
  title: "Receba suas vendas com PIX D+0",
  desc: "Agilidade nas suas transações! Sem complicações.",
  className: "md:col-span-2",
  thumbnail: "https://www.cartaoatacadao.com.br/documents/1210339/1215905/saque-rapido.png/2c498907-8437-35e0-6cf1-1a284039eaf5?t=1595877019134&download=true",
},

{
  id: 2,
  title: "Saque instantâneo",
  desc: "Seu dinheiro na hora que você precisa!\n\nDesfrute da liberdade de sacar seus ganhos instantaneamente, sempre que precisar, sem esperar dias para ter acesso ao seu dinheiro.",
  className: "md:col-span-2",
  thumbnail: "",
},


{
  id: 3,
  title: "Taxa de Aprovação de 98,70%",
  desc: "Aprovamos mais, para você vender mais!\n\nCom uma taxa de aprovação de 98,70%, garantimos que suas vendas sejam aceitas e processadas com eficiência.",
  className: "md:col-span-2",
  thumbnail: "",
},

  {
    id: 4,
    title: "Suporte 24 horas por dia",
    desc: "Estamos sempre ao seu lado!\n\nConte com nossa equipe dedicada, disponível 24/7 para ajudar você a resolver qualquer dúvida ou desafio.",
    className: "md:col-span-2",
    thumbnail: "",
  },
];

export const socialMedia = [
  {
    id: 1,
    img: "termos",
  },
  {
    id: 2,
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Instagram_logo_2022.svg/1200px-Instagram_logo_2022.svg.png",
  },
  {
    id: 3,
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Instagram_logo_2022.svg/1200px-Instagram_logo_2022.svg.png",
  },
];
