import Link from 'next/link';
import { FaLocationArrow, FaFacebook, FaInstagram, FaYoutube, FaLinkedin } from "react-icons/fa";
import MagicButton from "./MagicButton";

const Footer = () => {
  return (
    <footer className="w-full pt-10 pb-6 relative bg-gradient-to-r from-purple-700 via-purple-800 to-purple-900 text-gray-300" id="contact">
      {/* Fundo com grid */}
      <div className="w-full absolute left-0 top-0 h-full pointer-events-none z-0">
        <img
          src="/footer-grid.svg"
          alt="grid"
          className="w-full h-full opacity-30"
        />
      </div>

      {/* Conteúdo principal do Footer */}
      <div className="relative z-10 flex flex-col items-center px-4">
        <img 
          src="https://firebasestorage.googleapis.com/v0/b/player-music-72d4d.appspot.com/o/img%2Ff24f2140-978a-4ae4-af06-8e13b5048c68.png?alt=media&token=3f919229-cd00-4a45-ab4a-4c90111b1473" 
          alt="Logotipo PagShield" 
          className="block w-80 h-auto mb-4"
        />
        
        <p className="text-gray-200 text-center text-lg mb-6">
          Mais que um gateway de pagamentos, somos parceiros na sua jornada!
        </p>

        <a href="https://app.pagshield.io/register">
          <MagicButton
            title="Criar conta agora"
            icon={<FaLocationArrow />}
            position="right"
          />
        </a>
      </div>

      {/* Divisor para separar as seções */}
      <div className="relative z-10 my-8 w-full h-px bg-gray-700"></div>

      {/* Links de Políticas e Suporte */}
      <div className="relative z-10 flex flex-col md:flex-row justify-between w-full px-4 md:px-12">
        <div className="flex flex-col space-y-2 mb-8 md:mb-0">
          <h3 className="text-b-400 font-semibold">Políticas da PagShield</h3>
          <Link href="/privacidade" className="text-sm font-light text-gray-300 hover:text-white transition">Privacidade</Link>
          <Link href="/terms" className="text-sm font-light text-gray-300 hover:text-white transition">Termos de uso</Link>
          <Link href="/produtos-proibidos" className="text-sm font-light text-gray-300 hover:text-white transition">Produtos proibidos</Link>
          <Link href="/cookies" className="text-sm font-light text-gray-300 hover:text-white transition">Aviso de cookies</Link>
        </div>
        
        <div className="flex flex-col space-y-2 mb-8 md:mb-0">
          <h3 className="text-b-400 font-semibold">Suporte</h3>
       
          <Link href="/termos-compra" className="text-sm font-light text-gray-300 hover:text-white transition">Termos de compra</Link>
          <Link href="/espaco-consumidor" className="text-sm font-light text-gray-300 hover:text-white transition">Espaço do consumidor</Link>
          <Link href="/contato" className="text-sm font-light text-gray-300 hover:text-white transition">Contato</Link>
        </div>
        
        {/* Redes Sociais */}
        <div className="flex flex-col items-center md:items-start">
          <h3 className="text-gbreen-400 font-semibold mb-2">Siga-nos</h3>
          <div className="flex space-x-4">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-gray-300 hover:text-white transition">
              <FaFacebook size={20} />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-gray-300 hover:text-white transition">
              <FaInstagram size={20} />
            </a>
            <a href="https://youtube.com" target="_blank" rel="noopener noreferrer" className="text-gray-300 hover:text-white transition">
              <FaYoutube size={20} />
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="text-gray-300 hover:text-white transition">
              <FaLinkedin size={20} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
